<template>
  <div class="dashboard-editor-container">
    <panel-group :statistic-data="data" />

    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-chart :chart-data="personStatistic" />
    </el-row>
  </div>
</template>

<script>
import PanelGroup from './components/PanelGroup'
import LineChart from './components/LineChart'

export default {
  name: 'DashboardAdmin',
  components: {
    PanelGroup,
    LineChart
  },
  data() {
    return {
      data: {},
      personStatistic: null,
      // 在线人数
      peopleCountList: [],
      // 在线时长
      timeCountList: [],
      // 技能测评
      skillPeopleCount: [],
      // 素质测评
      qualityPeopleCount: []
    }
  },
  mounted() {
    this.getStatisticData()
  },
  methods: {
    // 获取统计信息
    getStatisticData() {
      this.$get('/statistic/park/getService').then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          this.data = data
          this.personStatistic = data.person
          const course = data.course
          course.forEach(item => {
            this.peopleCountList.push({ xAxis: item.dataHour, yAxis: item.peopleCount })
            this.timeCountList.push({ xAxis: item.dataHour, yAxis: item.timeCount })
          })
          const assess = data.assess
          assess.forEach(item => {
            this.skillPeopleCount.push({ xAxis: item.dataHour, yAxis: item.skillPeopleCount })
            this.qualityPeopleCount.push({ xAxis: item.dataHour, yAxis: item.qualityPeopleCount })
          })
        } else {
          this.$message.error(result.msg)
        }
      }).catch(() => {
        this.toggleLoading(false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 30px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 20px;
    margin-bottom: 32px;
  }
}
.statistic-block{
  width: 50%;
  .circle{
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .big-circle{
    border-width: 10px;
    border-style: solid;
    margin: 40px auto 20px;
    width: 170px;
    height: 170px;
  }
  .small-circle{
    border-width: 5px;
    border-style: solid;
    margin: 40px auto;
    width: 60px;
    height: 60px;
  }
  .item-title{
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .item-introduce{
    color: #9CA0AF;
    text-align: center;
    font-size: 13px;
  }
  .el-icon-top{
    color: rgb(225,17,0);
  }
  .el-icon-bottom{
    color: rgb(0,207,206);
  }
  .broken-statistic{
    width: 300px;height: 120px;margin-top: 10px
  }
  .statistic-increase{
    background-color: rgb(254,123,135);
  }
  .statistic-reduce{
    background-color: rgb(0,207,206);
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
